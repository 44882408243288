<template>
  <div class="selectCert">
    <el-form class="form" ref="form" size="small" :rules="rules" label-position="left" :model="formData" label-width="110px">
      <el-form-item label="数字证书类型" prop="certType">
        <el-radio-group v-model="formData.certType">
          <el-radio :label="2">机构证书</el-radio>
          <el-radio :label="3">机构个人证书</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="证件类型" prop="identType">
        <el-select v-model="formData.identType" placeholder="请选择证件类型" size="small">
          <el-option v-if="formData.certType==2" label="统一社会信用代码" value="Q"></el-option>
          <el-option v-else label="居民身份证" value="0"></el-option>
          <el-option label="其他" value="Z"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码" prop="identNo">
        <el-input v-model="formData.identNo" placeholder="请输入证件号码"></el-input>
      </el-form-item>
    </el-form>
    <el-button class="selectButn" :loading="selectLoading" @click="getReplaceCertList" type="primary">查询证书</el-button>
    <el-table v-if="certList.length>0" class="list-table" :data="certList" style="width: 100%">
      <el-table-column prop="signCertSn" label="证书序列号" width="260">
      </el-table-column>
      <el-table-column prop="commonName" label="证书名称">
      </el-table-column>
      <el-table-column prop="certType" label="证件类型" width="120">
        <template slot-scope="scope">
          {{CertType(scope.row.certType)}}
        </template>
      </el-table-column>
      <el-table-column prop="appType" label="办证渠道" width="120">
        <template slot-scope="scope">
          {{appType(scope.row.appType)}}
        </template>
      </el-table-column>
      <el-table-column prop="certStartTime" width="200" label="申请时间">
      </el-table-column>
      <el-table-column prop="certEndTime" width="200" label="到期时间">
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="certReissue(scope.row.signCertSn)" type="text">补办</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { appType, CertType } from '@/utils/typeMatch'
export default {
  props: ['projectId'],
  data () {
    return {
      CertType,
      appType,
      formData: {
        identType: '',
        identNo: '',
        certType: '',
        projectId: '',
        pageIndex: 0,
        pageSize: 100
      },

      certList: [],
      rules: {
        identType: [
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        identNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' }
        ],
        certType: [
          { required: true, message: '请选择数字证书类型', trigger: 'change' }
        ]
      },
      selectLoading: false
    }
  },
  created () {
    this.formData.projectId = this.projectId
  },
  methods: {
    getReplaceCertList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.selectLoading = true
          this.$api
            .getReplaceCertList(this.formData)
            .then(res => {
              this.certList = res.data.records || []
              this.selectLoading = false
              if (this.certList.length === 0) {
                this.$alert('未查询到相关证书', '提示', {
                  confirmButtonText: '我知道了',
                  type: 'warning'
                })
              }
            })
            .catch(() => {
              this.selectLoading = false
            })
        }
      })
    },
    certReissue (certSn) {
      this.$api.getCertBySn({ certSn, transType: 9 }).then(res => {
        if (res.code === '0') {
          this.$parent.certInfo = { ...res.data, certSn }
          this.$parent.agreementShow = true
        } else if (res.code === '306') {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '继续办理',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
          })
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => { }
          })
        }
      })
    }
  },
  watch: {
    'formData.certType': function () {
      this.formData.identType = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.selectCert {
  padding: 72px 16px;
  .form {
    width: 400px;
    margin: 0 auto;
    margin-bottom: 64px;
  }
  .selectButn {
    display: block;
    margin: 0px auto 82px;
    width: 182px;
  }
  ::v-deep.el-table {
    td,
    th.is-leaf {
      border-bottom: 1px solid #dee0e9;
    }
    .notInvoiceBtn {
      width: 58px;
    }
    .cell {
      padding-left: 24px;
      color: #2a2b2c;
    }
    tr > th {
      background-color: #f7faff;
      border-radius: 4px 4px 0px 0px;
      padding: 7px 0;
      .cell {
        font-size: 14px;
        color: #6c737b;
        font-weight: 500;
      }
    }
  }
}
</style>