<template>
  <div class="publicGuidelines">
    <div class="publicTitle">补办指南</div>
    <div class="publicListMain">
      <div class="publicTitle">办理流程</div>
      <ul>
        <li>查询证书</li>
        <li>立即补办</li>
        <li class="publicOverlength">确定补办信息</li>
        <li>上传资料</li>
        <li>资料审核 </li>
        <li>寄送</li>
        <li>业务完成 </li>
      </ul>
    </div>
    <div class="publicListMain">
      <div class="publicTitle">所需证明资料 </div>
      <div>
        <div class="publicMeans">1、【机构证书】</div>
        <p>（1）信息备案成功后，下载《数字证书申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>
      <div>
        <div class="publicMeans publicOther">2、【机构个人证书】</div>
        <p>（1）信息备案成功后，下载《数字证书申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）证书持有人身份证原件复印件，加盖公章</p>
        <p>（5）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>

    </div>
    <div class="publicListMain">
      <div class="publicTitle">下载电子发票 </div>
      <p>业务完成之后，登陆豸信CA官网（www.zxca.cn），进入个人中心，已完成的业务单可支持开据发票</p>
    </div>
    <div class="publicBtn">
      <el-button @click="stepActiveClick" type="primary">补办申请</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'guide',
  data() {
    return {}
  },
  created() {},
  methods: {
    stepActiveClick() {
      // this.$parent.stepActive = -1
      this.$parent.guideShow = true
    }
  }
}
</script>
