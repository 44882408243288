<template>
  <el-dialog title="" class="qrCodeDialog" append-to-body :visible.sync="dialogShow" width="578px">
    <h2 class="title">物流信息</h2>
    <div class="mailCompany" v-loading='qrLoading'>
      <img class="logo" v-if="activities.logo" :src="activities.logo" alt="logo" srcset="">
      <div class="expBox">
        <p class="expName">{{activities.expName}}</p>
        <p class="number">快递单号：<span>{{activities.expId}}</span></p>
        <p class="expPhone">官方电话：<span>{{activities.expPhone}}</span></p>
      </div>
    </div>
    <div class="mailSend" v-if="(activities.list)">
      <el-scrollbar>
        <el-timeline>
          <el-timeline-item v-for="(activity, index) in activities.list" :key="index" :color="activity.color" :timestamp="activity.time">
            {{activity.status}}
          </el-timeline-item>
        </el-timeline>
      </el-scrollbar>
    </div>
    <div class="mailSend" v-if="(activities.title)">{{activities.title}}</div>
    <div class="mailClose">
      <el-button @click="getmClose" type="primary">好的</el-button>
    </div>
  </el-dialog>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      dialogShow: false,
      qrLoading: false,
      activities: {}
    }
  },
  methods: {
    getmailNewData(id) {
      this.dialogShow = true
      this.qrLoading = true
      this.activities = {}
      axios({
        url: 'https://wuliu.market.alicloudapi.com/kdi',
        method: 'get',
        params: {
          no: id || '',
          type: ''
        },
        headers: {
          Authorization: 'APPCODE ' + 'dfdc3b3cd6504ea8a1e5194419703c73'
        }
      })
        .then(({ data }) => {
          if (data.status === '0') {
            if (data.result.list.length > 0) {
              data.result.list.forEach((element, index) => {
                if (index === 0) {
                  element.color = '#303B50'
                } else {
                  element.color = '#DEE2E8'
                }
              })
            } else {
              data.result.title = '没有信息'
            }
            data.result.expId = id
            this.activities = data.result
          } else {
            this.activities = { title: data.msg, expId: id }
            this.$store.dispatch('common/LogMessage', {
              message: data.msg,
              type: 4
            })
          }
          this.qrLoading = false
        })
        .catch(error => {
          this.activities = { title: '' }
          this.qrLoading = false
          this.$store.dispatch('common/LogMessage', { message: error, type: 4 })
        })
    },
    getmClose() {
      this.dialogShow = false
      this.activities = {}
    }
  }
}
</script>
<style lang="scss" scoped>
.qrCodeDialog {
  text-align: center;
  ::v-deep .el-dialog {
    .el-dialog__body {
      padding: 0;
    }
    .title {
      font-weight: bold;
      color: #303b50;
      line-height: 21px;
      font-size: 16px;
    }
    .logo {
      width: 50px;
      height: 100%;
      max-height: 70px;
      margin-top: 3px;
    }
    .mailCompany {
      display: flex;
      justify-content: flex-start;
      padding: 32px 0 32px 48px;
      border-bottom: 1px solid #dee2e8;
      .expBox {
        padding-left: 42px;
      }
      .expName {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #303b50;
        line-height: 21px;
      }
      .number,
      .expPhone {
        text-align: left;
        font-size: 14px;
        color: #aeb5be;
        line-height: 19px;
        padding-top: 8px;
        span {
          color: #303b50;
        }
      }
    }
    .mailSend {
      height: 400px;
      padding: 20px 0;
      .el-scrollbar {
        height: 100%;
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
        .el-scrollbar__view {
          padding: 0px 42px;
        }
      }
      .el-timeline-item {
        padding-bottom: 0;
        margin-bottom: 20px;
        text-align: left;
      }
      .el-timeline-item__node--normal {
        width: 8px;
        height: 8px;
        left: 1px;
        top: 2px;
      }
      .el-timeline-item__tail {
        top: 16px;
      }
    }
    .mailClose {
      border-top: 1px solid #dee2e8;
      padding: 16px 0;
      .el-button {
        width: 130px;
        height: 36px;
        line-height: 36px;
        padding: 0;
        border-radius: 4px;
      }
    }
  }
}
</style>